import React from 'react';
import Button from '../../components/Button';
import Layout from '../../components/layout/Layout';

export default () => (
  <Layout isRoot={false}>
    <section id="privacy" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Privacy Policy</h2>
        <p><i>Last Updated: 05-Nov-2020</i></p>
        <div className="text-left w-auto inline-block mx-5">
            <h3 className="text-l font-semibold mt-6">Information we collect</h3>
            <p>PDF Table Util does not collect <b>any</b> data or user data.</p>
            <h3 className="text-l font-semibold mt-6">Tracking Software</h3>
            <p>No tracking software is used by PDF Table Util</p>
            <h3 className="text-l font-semibold mt-6">Google Add-Ons</h3>
            <p>The embedded PDF Table Util does not collect any Google data or Google user data.</p>
            <h3 className="text-l font-semibold mt-6">Microsoft Add-Ins</h3>
            <p>The embedded PDF Table Util does not collect any Microsoft data or Microsoft user data.</p>
        </div>
      </div>
    </section>
    <section className="py-20 bg-gray-200 text-center">
      <h3 className="text-5xl font-semibold">Have Questions?</h3>
      {/*<p className="mt-8 text-xl font-light">
        FeedbackInterested in PDF Table Util?
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>*/}
      <p className="mt-8">
        <Button size="xl" onClick={() => {
          window.location = 'mailto:pdftableutil@gmail.com'
        }}>Equire Now</Button>
      </p>
    </section>
  </Layout>
);
